import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../src/layouts/PageLayout'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getImage } from 'gatsby-plugin-image'
import LinkOrButton from '../../src/components/LinkOrButton'

const BlogPostTemplate = ({ data }) => {
	let postData = data.wpBlogPost

	return (
		<PageLayout pageData={postData}>
			<BlogPostContentWrapper>
				<div dangerouslySetInnerHTML={{ __html: postData.postContent.text }} />
				{postData.postContent.hasBottomButton && (
					<LinkOrButton type={'button'} url="/offerte-aanvragen" text="Vraag nu uw offerte aan" />
				)}
				<br />
				<br />
				<br />
				{postData.postContent.blogimage && (
					<BlogImage
						image={getImage(postData.postContent.blogimage.localFile)}
						alt={postData.postContent.blogimage.altText}
					/>
				)}
			</BlogPostContentWrapper>
		</PageLayout>
	)
}

const BlogPostContentWrapper = styled.section`
	padding: 80px 0;
`

const BlogImage = styled(GatsbyImage)`
	height: 100%;
`

export default BlogPostTemplate


    export const query = graphql`
      query PageQuery1482($id: String!) {
				wpBlogPost(id: {eq: $id}) {
					
		id
		title
		uri
		postContent {
			title
			text
			hasBottomButton
			blogimage {
				altText
				sourceUrl
				localFile {
					childImageSharp {
						gatsbyImageData(width: 700, height: 450)
					}
				}
			}
		}
		header {
			
		heroChoice
		image {
			sourceUrl
			localFile {
				childImageSharp {
					gatsbyImageData(width: 1920, height: 800)
				}
			}
		}
		videoUrl
		height
		fieldGroupName
		backgroundOpacity
		backgroundColor
		pxOrVh
		heroText
  
		}
		seo {
			
		title
		metaDesc
		focuskw
		metaKeywords
		metaRobotsNoindex
		metaRobotsNofollow
		opengraphTitle
		opengraphDescription
		opengraphImage {
			altText
			sourceUrl
			srcSet
		}
		twitterTitle
		twitterDescription
		twitterImage {
			altText
			sourceUrl
			srcSet
		}
		canonical
		cornerstone
		schema {
			articleType
			pageType
			raw
		}
  
		}
  
				}
      }
    `
  
